html,
body {
  height: 100%;
  overflow: hidden;
}

#root {
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mui-fixed {
  padding-right: 0 !important;
}

.modal-form {
  height: 100%;
}

.k-notification-group {
  z-index: 1001 !important;
}

@media print {
  nav,
  header {
    display: none !important;
  }
  html,
  body {
    overflow-x: visible;
    overflow-y: visible;
  }
}
